.zoom.modal {
    padding: 0 0 0 0 !important;
}

.zoom.modal>.modal-dialog {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
}

.zoom.modal>.modal-dialog>.modal-content {
    margin: 0 0 0 0 !important;
    width: 100vw;
    height: 100vh;
    background-color: #0000;
}

.modal-ab {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

path {
    fill: currentColor;
}