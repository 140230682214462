@media (max-width: 576px) {
    .testimonial {
        padding: 2rem 2rem;
    }

    .t-body {
        padding: 1rem 1rem;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .testimonial {
        padding: 3rem 3rem;
    }

    .t-body {
        padding: 2rem 2rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .testimonial {
        padding: 3rem 3rem;
    }

    .t-body {
        padding: 2rem 2rem;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .testimonial {
        padding: 4rem 4rem;
    }

    .t-body {
        padding: 3rem 3rem;
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .testimonial {
        padding: 4rem 4rem;
    }

    .t-body {
        padding: 3rem 3rem;
    }
}

@media (min-width: 1400px) {
    .testimonial {
        padding: 5rem 5rem;
    }

    .t-body {
        padding: 4rem 4rem;
    }
}