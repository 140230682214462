.gal-iten {
    position: relative;
    overflow: hidden;
}

.gal-iten>.caption {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 1fr;
    height: 1fr;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.25s;
    padding: 10px 10px 10px 10px;
}

.simple>.caption {
    opacity: 0;
}

.simple:hover>.caption {
    opacity: 1;
}

.top>.caption {
    transform: translateY(-110%);
}

.top:hover>.caption {
    transform: translateY(0);
}

.bottom>.caption {
    transform: translateY(110%);
}

.bottom:hover>.caption {
    transform: translateY(0);
}

.right>.caption {
    transform: translateX(110%);
}

.right:hover>.caption {
    transform: translateX(0);
}

.left>.caption {
    transform: translateX(-110%);
}

.left:hover>.caption {
    transform: translateX(0);
}

.scaled>.caption {
    transform: scale(0, 0);
}

.scaled:hover>.caption {
    transform: scale(1, 1);
}

.scaledX>.caption {
    transform: scaleX(0);
}

.scaledX:hover>.caption {
    transform: scaleX(1);
}

.scaledY>.caption {
    transform: scaleY(0);
}

.scaledY:hover>.caption {
    transform: scaleY(1);
}

.rotate>.caption {
    transform: scale(0) rotate(180deg);
}

.rotate:hover>.caption {
    transform: scale(1) rotate(0deg);
}