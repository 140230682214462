h1 {
    font-size: 24px;
    min-height: 24px;
}

h2 {
    font-size: 20px;
    min-height: 20px;
}

h3 {
    font-size: 17px;
    min-height: 17px;
}

h4 {
    font-size: 15px;
    min-height: 15px;
}

p {
    font-size: 12px;
    min-height: 12px;
}


@media (min-width: 576px) {
    h1 {
        font-size: 24px;
        min-height: 24px;
    }

    h2 {
        font-size: 20px;
        min-height: 20px;
    }

    h3 {
        font-size: 17px;
        min-height: 17px;
    }

    h4 {
        font-size: 15px;
        min-height: 15px;
    }

    p {
        font-size: 12px;
        min-height: 12px;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 24px;
        min-height: 24px;
    }

    h2 {
        font-size: 20px;
        min-height: 20px;
    }

    h3 {
        font-size: 18px;
        min-height: 18px;
    }

    h4 {
        font-size: 16px;
        min-height: 16px;
    }

    p {
        font-size: 13px;
        min-height: 13px;
    }
}

@media (min-width: 992px) {
    h1 {
        font-size: 26px;
        min-height: 26px;
    }

    h2 {
        font-size: 24px;
        min-height: 24px;
    }

    h3 {
        font-size: 22px;
        min-height: 22px;
    }

    h4 {
        font-size: 18px;
        min-height: 18px;
    }

    p {
        font-size: 14px;
        min-height: 14px;
    }
}

@media (min-width: 1200px) {
    h1 {
        font-size: 26px;
        min-height: 26px;
    }

    h2 {
        font-size: 23px;
        min-height: 23px;
    }

    h3 {
        font-size: 20px;
        min-height: 20px;
    }

    h4 {
        font-size: 17px;
        min-height: 17px;
    }

    p {
        font-size: 15px;
        min-height: 15px;
    }
}

@media (min-width: 1400px) {
    h1 {
        font-size: 28px;
        min-height: 28px;
    }

    h2 {
        font-size: 24px;
        min-height: 24px;
    }

    h3 {
        font-size: 20px;
        min-height: 20px;
    }

    h4 {
        font-size: 18px;
        min-height: 18px;
    }

    p {
        font-size: 15px;
        min-height: 15px;
    }
}