
.map-conten {
    height: 400px !important;
}

.map-block {
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute !important;
}

.map-row {
    height: 100% !important;
    width: 100% !important;
}

.map-row>.col {
    margin-block: 1.5rem !important;
    height: calc(100% - 3rem) !important;
}

@media (max-width: 991px) {
    .map-conten {
        height: 800px !important;
        position: relative;
    }

    .map-block {
        display: block;
        position: relative !important;
        width: 100%;
        height: 400px !important;
    }

    .map-row.row {
        width: 100% !important;
        margin-inline: 0;
    }
}