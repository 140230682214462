details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

.col-pregunta>.card {
    border-radius: 0;
}

.col-pregunta>.card:first-child {
    border-top-right-radius: .25rem;
    border-top-left-radius: .25rem;
}

.col-pregunta>.card:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}