.pestanas.flex-row>ul,
.pestanas.flex-row-reverse>ul {
    width: 20%;
    height: 100%;
    flex-direction: column !important;
}

.pestanas.flex-row>div,
.pestanas.flex-row-reverse>div {
    width: 80%;
}

.pestanas.flex-row>.tab>li,
.pestanas.flex-row-reverse>.tab>li {
    margin: -10px 0 -10px 0;
    padding: 10px 5px 10px 5px !important;
}

.pestanas.flex-row>.tab>li>.p-1,
.pestanas.flex-row-reverse>.tab>li>.p-1 {
    margin-top: 5px;
}

.pestanas.flex-row>.tab>li:first-child,
.pestanas.flex-row-reverse>.tab>:first-child {
    margin-top: 0;
}

.pestanas.flex-row>.tab>li:last-child,
.pestanas.flex-row-reverse>.tab>li:last-child {
    margin-bottom: 0;
}

.pestanas.flex-column-reverse>.tab>li,
.pestanas.flex-column>.tab>li {
    margin: 0 -10px 0 -10px;
    padding: 5px 30px 5px 30px !important;
}

.pestanas.flex-column-reverse>.tab>li:first-child,
.pestanas.flex-column>.tab>li :first-child {
    margin-left: 0;
}

.pestanas.flex-column-reverse>.tab>li:last-child,
.pestanas.flex-column>.tab>li:last-child {
    margin-right: 0;
}

.pestanas.flex-column>.tab>li {
    border-radius: 25px 25px 0 0;
}

.pestanas.flex-column-reverse>.tab>li {
    border-radius: 0 0 25px 25px;
}

.pestanas.flex-row>.tab>li {
    border-radius: 25px 0 0 25px;
}

.pestanas.flex-row-reverse>.tab>li {
    border-radius: 0 25px 25px 0;
}