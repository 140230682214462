.box {
    padding: 60px 0;
    padding: 0;
    max-height: 100%;
    width: 100%;

    @media (max-width: 991px) {
        padding: 30px 0;
    }
}

.paralax {
    overflow: hidden;
}

.c-bg-parallax {
    background-position: center center;
    background-size: cover;
}

.c-content-area {
    width: 40%;
    height: 100%;
    margin-bottom: -100%;
}

@media (min-width: 992px) {
    .box {
        overflow: hidden;
    }

    /* .paralax {
        aspect-ratio: 16/9;
    } */

    .c-bg-parallax {
        background-attachment: fixed;
        background-color: initial;
        height: 100%;
        margin-bottom: -100%;
        min-height: 100%;
        width: 100%;
    }

    .c-feature-content {
        padding: 60px 0;
        height: 100%;
        padding: 0;
        width: 40%;
        position: relative;
    }

    .c-feature-content>.c-content-v-center {
        height: 100%;
        padding: 5rem;
    }

    .c-feature-content>.c-content-v-center>.c-wrapper {
        display: table;
    }

    .c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
        display: table-cell;
        vertical-align: middle;
    }
}

@media (max-width: 991px) {
    .c-bg-parallax {
        width: 100%;
        min-height: 350px;
    }

    .c-content-area {
        width: 0px;
        display: none;
    }

    .paralax>svg,
    .paralax>div>svg {
        display: none;
    }

    .c-feature-content,
    .c-feature-content>.c-content-v-center,
    .c-feature-content>.c-content-v-center>.c-wrapper,
    .c-feature-content>.c-content-v-center>.c-wrapper>.c-body {
        height: fit-content;
        padding: .3rem;
    }

}