.imagen-banner.slick-dots li button:before {
    display: none !important;
}

.imagen-banner-bottom {
    height: 2.5rem;
    margin-bottom: -2.1rem;
}

.imagen-banner-top {
    height: 2.5rem;
    margin-top: -2.1rem;
}

.imagen-banner img {
    filter: grayscale(1);
    height: 3rem;
}

.imagen-banner img:hover {
    filter: grayscale(0);
}

@media (min-width: 992px) {
    .imagen-banner-bottom {
        height: 3rem;
        margin-bottom: -2.5rem;
    }

    .imagen-banner-top {
        height: 3rem;
        margin-top: -2.5rem;
    }

    .imagen-banner img {
        height: 3.5rem;
    }
}

@media (min-width: 1200px) {
    .imagen-banner-bottom {
        height: 4.5rem;
        margin-bottom: -4rem;
    }

    .imagen-banner-top {
        height: 4.5rem;
        margin-top: -4rem;
    }

    .imagen-banner img {
        height: 6.5rem;
    }
}

@media (pointer:none),
(pointer:coarse) {

    .imagen-banner.slick-prev:before,
    .imagen-banner.slick-next:before,
    .slick-dots li:before {
        display: none !important;
    }
}